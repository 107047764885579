(function () {
    'use strict';
    // DO NOT EDIT THIS FILE, EDIT THE GULP TASK NGCONSTANT SETTINGS INSTEAD WHICH GENERATES THIS FILE
    angular
        .module('cinecaLandingApp')
        .constant('VERSION', "1.137.2-SNAPSHOT")
        .constant('DEBUG_INFO_ENABLED', false)
        .constant('CINECA_USERS_GROUPS_URLS', {
	"USERS": "https://ciam-authservice.ose.cineca.it/authservice/app/users?embed=1&theme=cineca",
	"GROUPS": "https://ciam-authservice.ose.cineca.it/authservice/app/teams?embed=1&theme=cineca",
	"ATTRIBUTES": "https://ciam-authservice.ose.cineca.it/authservice/app/attributes?embed=1&theme=cineca"
})
        .constant('CINECA_USERS_GROUPS_URLS_PP', {
	"USERS": "https://ciam-authservice-pp.ose.private.cineca.it/authservice/app/users?embed=1&theme=cineca",
	"GROUPS": "https://ciam-authservice-pp.ose.private.cineca.it/authservice/app/teams?embed=1&theme=cineca",
	"ATTRIBUTES": "https://ciam-authservice-pp.ose.private.cineca.it/authservice/app/attributes?embed=1&theme=cineca"
})
;
})();
